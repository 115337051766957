.App {
  /* text-align: center; */
  overflow-y:hidden;
}

.logo-bank {
  flex-direction: column;
  align-self: center;
  justify-self: center;
}

.input-box {
  margin-top: 3px;
  padding: 10px 5px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #dcdcdc;
}

/* .input-box{
  outline: none;
  border: none;
} */
.input-box:hover{
  outline-width: 1px;
  outline-color: #bcffe5;
}
.input-box:focus{
  outline-width: 1px;
  outline-color: #bcdeff;
}


.remaining-time {
  text-align: center;
  background-color: rgb(241, 191, 191);
  padding: 1px;
  border-radius: 10px;
  color: darkred;
  margin: 1%;
  padding: 0 2%;
}

.panel-style {
  margin-bottom: 24;
  background: rgba(0, 0, 0, 0.02);
  border-radius: 8;
  border: 'none'
}