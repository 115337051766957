.custom-steps-container {
    padding: 1% 10%;
    background: rgb(23,70,162);
    background: linear-gradient(317deg, rgba(23,70,162,1) 35%, rgba(95,157,247,1) 100%);
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    margin-bottom: 2%;
    overflow: hidden;
}
.line{
    border: rgb(255, 255, 255) 1px dashed;
    width:100%;
  }
  
  .line-inactive {
    border-top: rgba(95,157,247,1) 1px dashed;
    width:100%;
  
  }
  
  .step {
    text-align: center;
    color: #ffffff;
    font-weight: 300;
  }
  
  .step-inactive {
    text-align: center;
    color: rgba(95,157,247,1);
    font-weight: 200;
  }
  
  .step-desc {
    font-size:small
  }
  
  .step-number {
    /* background-color: #ffffff;
    border-radius: 100%;
    width: min-content; */
    color: rgb(255, 255, 255);
    font-weight: bolder;
  }
  
  .step-number-inactive {
    color: rgba(95,157,247,1);
    font-weight: normal;
  }